@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --dark-gray: #232429;
    --gray: #939393;
    --light-gray: #e8e8e8;
    --white: #ffffff;
    --secondary-red: #C33535;
    --dark-red: #D9000D;
    --light-red: #FBEBEB;
    --green: #2C802A;
    --light-green: #E2F5E2;
    --light-brown: #CC8A22;
    --light-yellow: #FFFFCB;
    --orange: #FF7A24;
    --light-orange: #FFF2E5;
    --dark-blue: #3580C3;
    --light-blue: #E4F3FC;
}

html {
    width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: var(--gray);
    line-height: 21px;
    width: 100%;
    overflow-x: hidden;
}

#root {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button, button:focus {
  outline: none;
}

.page-title {
    text-align: center;
    color: var(--dark-gray);
    font-size: 29px;
    font-weight: 700;
    line-height: 31px;
    margin: 0 0 10px 0;
}

.ot-floating-button__open,
.ot-floating-button__close{
    display: flex;
    justify-content: center;
    align-items: center;
}

button {
    position: relative;
    overflow: hidden;
}

.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 0.6s linear;
    opacity: 0.3;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}


@media (max-width: 1331px) {
    .MuiContainer-root {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

@media (max-width: 600px) {
    .page-title {
        font-size: 25px;
    }
}

/* Hide scrollbar */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}